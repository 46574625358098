import SubscriptionDataContext from "@context/SubscriptionDataContext";
import { type ComponentType, useContext } from "react";
import { Navigate } from "react-router-dom";

const withRedirectOnMissingData = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return (props: P) => {
    const { data } = useContext(SubscriptionDataContext);
    if (!data.numberOfLines || !data.selectedPlan)
      return <Navigate to={"/basic-info"} />;

    return <WrappedComponent {...props} />;
  };
};

export default withRedirectOnMissingData;
